import React from "react";

const Footer = () => {
  return (
    <div className="bg-darkBlue text-white font-light text-sm">
      <p className="text-center p-8">
        NuPay Building, Cnr Ring Rd East, & Voortrekker Rd, New Market Mall,
        Alberton, Gauteng 1450, 0116171700
      </p>
      <div className="h-0 border-t border-gray-400"></div>
      <div>
        <p className="p-4 ml-8">
          © 2022 Altron Fintech | A Division of Altron.
        </p>
      </div>
    </div>
  );
};

export default Footer;
