import React from "react";
import Container from "../../components/Container/Container";
import ForgotpasswordForm from "../../components/ForgotPasswordForm/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <Container>
      <ForgotpasswordForm />
    </Container>
  );
};

export default ForgotPassword;
