import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormError from '../FormError/FormError';
import { useAppState } from '../../state';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../../utils';

const schema = yup
  .object({
    email: yup.string().email().required('This field is required'),
    password: yup.string().required('This field is required'),
  })
  .required();

export default function LoginForm() {
  const { login } = useAppState();

  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect');

  // let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      toast.promise(
        login(data.email, data.password),
        {
          loading: 'Logging in...',
          success: <p>Welcome!</p>,
          error: (err) => {
            const errorMessage = getErrorMessage(err);
            return errorMessage;
          },
        },
        {
          error: {
            duration: 10000,
          },
          success: {
            duration: 5000,
          },
        }
      );
      // history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 px-4 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            src="/eh-logo.png"
            alt=""
            className="text-center max-w-xs mx-auto"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blueGray">
            Login to your account
          </h2>
          <p className="text-center mt-4 text-sm">
            Please note the Everyday Hero Awards programme is anonymous. Your
            login details will only be used to assist in cases where trouble
            shooting is necessary.
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register('email')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.email?.message}</FormError>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    {...register('password')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.password?.message}</FormError>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-medium text-darkBlue hover:text-indigo-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-darkBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
          <p className="text-center mt-4">
            Don't have an account?{' '}
            <a
              className="underline text-lightBlue"
              href={redirect ? `/sign-up?redirect=${redirect}` : '/sign-up'}
            >
              Sign up
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
