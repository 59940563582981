import React, {useEffect, useRef} from "react";
import Container from "../../components/Container/Container";
import HSNominationForm from "../../components/HSNominationForm/HSNominationForm";
import Page from "../../components/Page/Page";
import {getStorage, ref, getDownloadURL} from "firebase/storage"
import {NominationForm} from "../../components/HSNominationForm/NominationForm";


const Nomination = () => {
    const imgRef = useRef(null);

    useEffect(() => {
        const storage = getStorage();
        const imageRef = ref(storage, 'page_banner.png');

        getDownloadURL(imageRef)
            .then((url) => {
                // Set the 'src' attribute of the imgRef.current to the obtained URL
                if (imgRef.current) {
                    imgRef.current.setAttribute('src', url);
                }
            })
            .catch((error) => {
                // Handle any errors
            });
    }, []);

    return (
        <Page>
            <Container>
                <div className="mb-4">
                    <img ref={imgRef} alt="banner" id={'page_banner'}/>
                </div>
                <NominationHeading/>
                <NominationForm/>
            </Container>
        </Page>
    );
};

const NominationHeading = () => {
    return (
        <>
            <h2>Award Categories</h2>
            <div className={'sf__cata__wrapper'}>
                <div className={'sf__cata__container'}>
                    <div className={'sf__cata__header'}>
                        <h3>🚀Innovation🚀</h3>
                    </div>
                    <p>This category focuses on individuals who, through innovative thinking have made noticeable
                        improvements to the area they work in by introducing new ideas that either improve customer service,
                        products, reduce waste, cut costs, or create new opportunities for Altron FinTech.</p>
                </div>
                <div className={'sf__cata__container'}>
                    <div className={'sf__cata__header'}>
                        <h3>💖Customer Service💖</h3>
                    </div>
                    <p>The focus here is on individuals who, through their outstanding efforts or talents, have consistently
                        provided excellent service to our external customers.</p>
                </div>
                <div className={'sf__cata__container'}>
                    <div className={'sf__cata__header'}>
                        <h3>👟Extra Mile👟</h3>
                    </div>
                    <p>This category takes a closer look at individuals who, through their outstanding efforts or talents,
                        have consistently provided excellent service to their internal customers.</p>
                </div>
            </div>
            <div className={'sf__best__player__container'}>
                <h3>✊Best Team Player✊</h3>
                <br/>
                <div className={'sf__teamplayer__table'}>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Committed</div>
                        <div className={'sf__table__content__container__content'}>They go the extra mile, give full commitment to the team and display servant team member
                        attributes.</div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Collaborative</div>
                        <div className={'sf__table__content__container__content'}>They are able to coordinate tasks, share ideas and work well with other team members and as well
                        as across other teams.</div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Dependable and reliable</div>
                        <div className={'sf__table__content__container__content'}>They are responsible and have good judgement skills, are consistent and hold themselves
                        accountable for their work. They take responsibility for their mistakes and look for solutions.
                        They understand how their actions impact the entire team.</div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Enthusiastic</div>
                        <div className={'sf__table__content__container__content'}>They have fun working with their teams and display a great positive attitude.</div>
                    </div>
                    <div className={'sf__table__content__container'}>
                        <div  className={'sf__table__content__container__header'}>Flexible</div>
                        <div className={'sf__table__content__container__content'}>They readily accept any tasks delegated. They look at every opportunity as a chance to learn.</div>
                    </div>
                </div>
            </div>
            <div className={'sf__nomination__heading'}>
                <h3>🏆 Tell us all about the person you are nominating 🏆</h3>
                <p>Please note that your award nomination is completely anonymous.</p>
            </div>
        </>
    )
}

export default Nomination;
