import React from "react";
import { useParams, Link } from "react-router-dom";

const CategoriesMenu = () => {
  const { department } = useParams();

  return (
    <div>
      <h3 className="text-2xl font-light text-center">
        Click below to access the different categories:
      </h3>
      <div className="flex md:flex-row flex-col items-center justify-between w-3/4 mx-auto font-bold my-8 mb-24">
        <Link to={`/nominations/${department}/best-team-player`}>Best Team Player</Link>
        <Link to={`/nominations/${department}/innovation`}>Innovation</Link>
        <Link to={`/nominations/${department}/customer-service`}>
          Customer Service
        </Link>
        <Link to={`/nominations/${department}/extra-mile`}>Extra Mile</Link>
        {/* <Link to={`/nominations/${department}/top-performer`}>
          Top Performer
        </Link> */}
      </div>
    </div>
  );
};

export default CategoriesMenu;
