import React from "react";
import useFirebase from "./useFirebase/useFirebase";

export const StateContext = React.createContext(null);

export default function AppStateProvider({ children }) {
  // const [isFetching, setIsFetching] = useState(false);

  let contextValue = {
    // isFetching,
  };

  contextValue = {
    ...contextValue,
    ...useFirebase(), // eslint-disable-line react-hooks/rules-of-hooks
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = React.useContext(StateContext);

  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
