import React from 'react';
import { useAppState } from '../../state';

const Nav = () => {
  const { signOutUser } = useAppState();

  return (
    <div className="py-4 fixed top-0 w-screen bg-gray-100 shadow-sm z-10">
      <div className=" flex items-center justify-between max-w-7xl mx-auto">
        <div className=" mx-auto md:mx-0" style={{ maxWidth: '150px' }}>
          <img src="/altron-logo.png" alt="logo" />
        </div>
        <div className="text-right">
          {/* <p>Welcome {user.displayName}</p> */}
          <button
            onClick={() => {
              signOutUser()
                // .then(() => console.log("signed out"))
                .catch((err) => console.log(err));
            }}
            className="underline cursor-pointer"
          >
            Sign out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Nav;
