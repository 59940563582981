export function isValidPassword(ref, message) {
  return this.test("isValidPassword", message, function (value) {
    const { path, createError } = this;

    let firstPassword = this.resolve(ref);
    if (value !== firstPassword) {
      return createError({
        path,
        message: message ?? "Passwords do not match",
      });
    }

    return true;
  });
}

export function isValidEmailDomain(message) {
  return this.test("isValidEmailDomain", message, function (value) {
    const { path, createError } = this;

    if (
      value.split("@")[1] !== "spitfireinbound.com" &&
      value.split("@")[1] !== "altron.com"
    ) {
      return createError({
        path,
        message: message ?? "Only Altron Fintech accounts are allowed",
      });
    }

    return true;
  });
}
