import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppState } from '../../state';

// const GuardedRoute = ({ component: Component, path, ...rest }) => {
//   const { user, authReady } = useAppState();

//   // React.useEffect(() => {
//   //   console.log("GuardedRoute user: ", user);
//   // }, [user]);

//   return (
//     <>
//       <Route
//         path={path}
//         render={() => {
//           return user ? <Component {...rest} /> : <Navigate to="/sign-up" />;
//         }}
//       />
//     </>
//   );
// };

const GuardedRoute = ({ children }) => {
  const { user } = useAppState();

  //get url
  const pathname = window.location.pathname;
  // console.log('pathname: ', pathname);
  // let relativeUrl = url.split("/").slice(3).join("/");

  return user ? (
    children
  ) : (
    <Navigate to={pathname === '/' ? `login` : `/login?redirect=${pathname}`} />
  );
};

export default GuardedRoute;
