import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormError from '../FormError/FormError';
import { isValidPassword, isValidEmailDomain } from '../../formUtils';
import { useAppState } from '../../state';
import toast from 'react-hot-toast';
import { getErrorMessage } from '../../utils';

yup.addMethod(yup.mixed, 'isValidPassword', isValidPassword);
yup.addMethod(yup.string, 'isValidEmailDomain', isValidEmailDomain);

const schema = yup
  .object({
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    email: yup
      .string()
      .isValidEmailDomain()
      .email()
      .required('This field is required'),
    password: yup.string().required('This field is required'),
    passwordConfirmation: yup
      .string()
      .isValidPassword(yup.ref('password'))
      .required('This field is required'),
  })
  .required();

export default function SignUpForm() {
  const { signUp } = useAppState();

  // let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      // console.log("Signing up.....");
      // const createdUser = await signUp(
      //   data.firstName,
      //   data.lastName,
      //   data.email,
      //   data.password
      // );
      // console.log("Created user: ", createdUser);
      toast.promise(
        signUp(data.firstName, data.lastName, data.email, data.password),
        {
          loading: 'Creating user...',
          success: <p>Welcome!</p>,
          error: (err) => {
            const errorMessage = getErrorMessage(err);
            return errorMessage;
          },
        },
        {
          error: {
            duration: 10000,
          },
          success: {
            duration: 5000,
          },
        }
      );
      // history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 px-4 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blueGray">
            Create an account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <div className="mt-1">
                  <input
                    id="firstName"
                    name="firstName"
                    type="text"
                    {...register('firstName')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.firstName?.message}</FormError>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Surname
                </label>
                <div className="mt-1">
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    {...register('lastName')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.lastName?.message}</FormError>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register('email')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.email?.message}</FormError>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    {...register('password')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.password?.message}</FormError>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password Confirmation
                </label>
                <div className="mt-1">
                  <input
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    autoComplete="current-password"
                    {...register('passwordConfirmation')}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                  <FormError>{errors.passwordConfirmation?.message}</FormError>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-darkBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign up
                </button>
              </div>
              <p className="text-center">
                Already have an account?{' '}
                <a className="underline text-lightBlue" href="/login">
                  Log in
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
