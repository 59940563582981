import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nomination from './pages/Nomination/Nomination';
import Nominations from './pages/Nominations/Nominations';
import Categories from './pages/Categories/Categories';
import SignUp from './pages/SignUp/SignUp';
import GuardedRoute from './components/GuardedRoute/GuardedRoute';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import { Toaster } from 'react-hot-toast';
import AppStateProvider from './state';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-PLYKNRMYBE';
ReactGA.initialize(TRACKING_ID);

function App() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="font-montserrat text-bluegray">
      <Toaster />
      <Router>
        <AppStateProvider>
          <Routes>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/nominations/:department"
              element={
                <GuardedRoute>
                  <Categories />
                </GuardedRoute>
              }
            />
            <Route
              path="/nominations/:department/:category"
              element={
                <GuardedRoute>
                  <Nominations />
                </GuardedRoute>
              }
            />
            <Route
              path="/nominations/:quarter/:department/:category"
              element={
                <GuardedRoute>
                  <Nominations />
                </GuardedRoute>
              }
            />
            <Route
              path="/nominations/quarter/:quarter/:department"
              element={
                <GuardedRoute>
                  <Categories />
                </GuardedRoute>
              }
            />
            {/* <GuardedRoute
            path={["/nominations/:department/:category"]}
            component={Nominations}
          />
          <GuardedRoute
            path={["/nominations/:department"]}
            component={Categories}
          /> */}
            {/* <GuardedRoute path="/" element={<Nomination />} /> */}
            <Route
              path="/"
              element={
                <GuardedRoute>
                  <Nomination />
                </GuardedRoute>
              }
            />
          </Routes>
        </AppStateProvider>
      </Router>
    </div>
  );
}

export default App;
