import React from 'react';
// import app from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";
import {initializeApp} from 'firebase/app';
import {
    getAuth,
    signOut,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    updateProfile,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    browserLocalPersistence,
    setPersistence,
} from 'firebase/auth';
import {
    getFirestore,
    collection,
    getDocs,
    doc,
    setDoc,
    serverTimestamp,
    query,
    where,
    // updateDoc,
    // increment,
} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

const getDateInMilliseconds = () => {
    const date = new Date().getTime();
    return date + '';
};

// const saveVoteToLocalStorage = (vote, quarter) => {
//   const currentYear = new Date().getFullYear();
//   //The votes190822 are before we updated to use year and quarter. We updated in the middle of voting (long story) which is why we're catering for both, but won't have to in future
//   const votesStorageName = quarter
//     ? `votes${currentYear}${quarter}`
//     : "votes190822";
//   const votes = JSON.parse(localStorage.getItem(votesStorageName)) || [];
//   votes.push(vote);
//   localStorage.setItem(votesStorageName, JSON.stringify(votes));
// };

export default function useFirebase() {
    const [nominations, setNominations] = React.useState([]);
    const [authReady, setAuthReady] = React.useState(false);
    const [user, setUser] = React.useState(null);

    let navigate = useNavigate();

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const auth = getAuth(app);

    // React.useEffect(() => {
    //   console.log('useFirebase user: ', user);
    // }, [user]);

    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            //get url params
            const urlParams = new URLSearchParams(window.location.search);
            const redirect = urlParams.get('redirect');
            // console.log('Redirect: ', redirect);
            if (user) {
                setUser(user);
                setAuthReady(true);
                // console.log(user, 'redirecting to /');
                navigate(redirect ? `${urlParams.get('redirect')}` : '/');
            } else {
                setUser(null);
                setAuthReady(true);
            }
        });
    }, []);

    React.useEffect(() => {
        getNominations()
            .then((data) => {
                setNominations(data);
                // console.log(data);
            })
            .catch((e) => console.log(e));
        //eslint-disable-next-line
    }, []);

    const addNewNomination = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(data)
                // let motivationData = createMotivationDataObj(data['awardCategory'], data['nominationDetails'])
                const id = getDateInMilliseconds();

                data['approved'] = false
                data['id'] = id
                data['createdAt'] = serverTimestamp()
                data['nominatedBy'] = {
                    email: user.email,
                    name: user.displayName,
                    uid: user.uid,
                }

                await setDoc(doc(db, 'nominations', id), data)

                resolve();
            } catch (err) {
                await setDoc(doc(db, 'errors', getDateInMilliseconds()), {
                    error: err,
                    createdAt: serverTimestamp(),
                    userEmail: user.email,
                    userDisplayName: user.displayName,
                    userUid: user.uid,
                });
                reject(err);
            }
        });
    };

    const createMotivationDataObj = (motivation, data) => {
        let motivationData = {
            'type': motivation.toLowerCase()
        }

        switch (motivation) {
            case "Extra Mile":
                const extraMileValues = [
                    'who_does_this_person_serve_internally_',
                    'how_did_the_nominee_meet_their_internal_customers__needs_in_a_way_that_went_above_and_beyond_expect',
                    'extra_mile_award_further_comments'
                ]

                return generateMotivationContent(extraMileValues, data, motivationData)

            case 'Top Team':
                const topTeamValues = [
                    'which_team_does_this_person_belong_to_',
                    'elaborate_on_how_the_person_makes_a_significant_contribution_to_the_team_and_is_an_excellent_role_m',
                    'best_team_player_award_further_comments'
                ]

                return generateMotivationContent(topTeamValues, data, motivationData)


            case 'Customer Service':
                const customerServiceValues = [
                    'who_does_this_person_serve_externally_',
                    'how_did_they_meet_their_external_customers__needs_in_a_way_that_went_above_and_beyond_expectations_',
                    'what_impact_did_this_service_have_on_the_external_customer__please_provide_specific_examples_',
                    'has_this_person_receive_written_compliments_from_the_customer_for_the_service_they_provided_',
                    'customer_service_award_further_comments'
                ]

                return generateMotivationContent(customerServiceValues, data, motivationData)

            case 'Innovation':
                const innovationValues = [
                    'describe_the_innovation_developed_and_implemented_by_the_person_being_nominated_',
                    'is_this_innovation_a_new_idea_or_is_it_an_improvement_on_something_that_already_exists_',
                    'please_provide_details_of_the_innovation_',
                    'who_will_use_this_innovation_',
                    'what_problem_does_this_innovation_solve_for_its_users_and___or_how_has_this_innovation_made_a_posit',
                    'innovation_award_further_comments'
                ]

                return generateMotivationContent(innovationValues, data, motivationData)

            default:
                motivationData['err'] = "No data found"
        }
    }

    const generateMotivationContent = (keyValues, data, motivationData) => {
        for (const key of keyValues) {
            if (key in data) {
                motivationData[key] = data[key]
            }
        }

        return motivationData
    }

    const getNominations = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let data = [];
                let snapshot = await getDocs(collection(db, 'nominations'));
                snapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                resolve(data);
            } catch (err) {
                reject(err);
            }
        });
    };

    const castVote = (id, category, name, department, jobTitle, user) => {
        return new Promise(async (resolve, reject) => {
            try {
                // await updateDoc(doc(db, "nominations", id), {
                //   votes: increment(1),
                // });
                // saveVoteToLocalStorage(category, quarter);

                const time = getDateInMilliseconds();

                await setDoc(doc(db, 'votes', time), {
                    nominationId: id,
                    category,
                    name,
                    department,
                    jobTitle,
                    by: {
                        email: user.email,
                        name: user.displayName,
                        uid: user.uid,
                    },
                    createdAt: serverTimestamp(),
                });

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    const signUp = (firstName, lastName, email, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                //set persistence
                await setPersistence(auth, browserLocalPersistence);

                let newUser = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                await updateProfile(newUser.user, {
                    displayName: firstName + ' ' + lastName,
                });

                await setDoc(doc(db, 'users', email), {
                    email,
                    firstName,
                    lastName,
                    createdAt: serverTimestamp(),
                });

                resolve(newUser.user);
            } catch (err) {
                console.log('ERROR', err);
                reject(err);
            }
        });
    };

    const login = (email, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                let user = await signInWithEmailAndPassword(auth, email, password);
                resolve(user.user);
            } catch (err) {
                console.log('ERROR:', err);
                reject(err);
            }
        });
    };

    const resetUserEmail = (email) => {
        return new Promise(async (resolve, reject) => {
            try {
                await sendPasswordResetEmail(auth, email);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    const signOutUser = () => {
        return new Promise(async (resolve, reject) => {
            try {
                await signOut(auth);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    const getVotesByUser = (user) => {
        return new Promise(async (resolve, reject) => {
            try {
                let data = [];
                let snapshot = await getDocs(
                    query(collection(db, 'votes'), where('by.uid', '==', user.uid))
                );
                snapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                resolve(data);
            } catch (err) {
                reject(err);
            }
        });
    };

    const logError = (error, user, where, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                await setDoc(doc(db, 'errors', getDateInMilliseconds()), {
                    error,
                    createdAt: serverTimestamp(),
                    user: {
                        email: user.email,
                        name: user.displayName,
                        uid: user.uid,
                    },
                    where,
                    data,
                });
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    return {
        signUp,
        signOutUser,
        authReady,
        addNewNomination,
        nominations,
        castVote,
        login,
        user,
        resetUserEmail,
        getVotesByUser,
        logError,
    };
}
