import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ title = "title", link, image }) => {
  return (
    <Link
      to={link}
      className="bg-white rounded-lg border-b-4 border-darkBlue p-8 hover:shadow-md transition-all duration-300 cursor-pointer"
    >
      <img src={image} alt="category" />
      <p className="text-2xl text-center font-semibold text-lightBlue">
        {title}
      </p>
    </Link>
  );
};

export default CategoryCard;
