import React, {useEffect, useRef} from "react";
import CategoriesMenu from "../../components/CategoriesMenu/CategoriesMenu";
import Container from "../../components/Container/Container";
import NominationListing from "../../components/NominationListing/NominationListing";
import Page from "../../components/Page/Page";
import {getDownloadURL, getStorage, ref} from "firebase/storage";

const Nominations = () => {
    const imgRef = useRef(null);

    useEffect(() => {
        const storage = getStorage();
        const imageRef = ref(storage, 'page_banner.png');

        getDownloadURL(imageRef)
            .then((url) => {
                // Set the 'src' attribute of the imgRef.current to the obtained URL
                if (imgRef.current) {
                    imgRef.current.setAttribute('src', url);
                }
            })
            .catch((error) => {
                // Handle any errors
            });
    }, []);

  return (
    <Page>
      <Container>
        <div className="mb-24">
          <img ref={imgRef} alt="banner" id={'page_banner'} />
        </div>
        <CategoriesMenu />
        <div className="mb-28">
          <NominationListing />
        </div>
      </Container>
    </Page>
  );
};

export default Nominations;
