import React from "react";
import Footer from "../Footer/Footer";
import Nav from "../Nav/Nav";

const Page = ({ children, showNav = true }) => {
  return (
    <div className="min-h-screen flex flex-col justify-between">
      {showNav && <Nav />}
      <div className="mt-36 mb-36">{children}</div>
      <Footer />
    </div>
  );
};

export default Page;
