import React from "react";
import Container from "../../components/Container/Container";
import SignUpForm from "../../components/SignUpForm/SignUpForm";

const SignUp = () => {
  return (
    <Container>
      <SignUpForm />
    </Container>
  );
};

export default SignUp;
