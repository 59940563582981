import React from 'react';
import {motion} from 'framer-motion';
import {useAppState} from '../../state';
// import { useParams } from "react-router-dom";

const Motivation = ({type, data}) => {
        // delete data.type
    console.log(data)
        return (
            <>
                {Object.keys(data).map((key, i) => {
                    const editedKey = (key.replace(/([A-Z])/g, ' $1').trim().replace(/^\w/, c => c.toUpperCase()).toUpperCase())
                    const value = data[key]
                    return (
                        <div key={i}>
                            <h4>{editedKey}</h4>
                            <p>{value}</p>
                        </div>
                    )
                })}
            </>
        )

    }
;

function Card({
                  id,
                  name,
                  department,
                  jobTitle,
                  category,
                  votingEnabled,
                  setVotingEnabled,
                  motivation,
                  testVotingEnabled,
                  hasVote,
              }) {
    const {castVote, user} = useAppState();

    const [showModal, setShowModal] = React.useState(false);

    // const { quarter } = useParams();

    return (
        <li className="card bg-white rounded-2xl border-l-4 border-darkBlue">
            <div className="card-content-container">
                {hasVote && (
                    <div className="absolute -top-10 w-full flex justify-center">
                        <div className="bg-green-600 text-white inline-block mx-auto py-1 px-4 rounded-xl">
                            Voted
                        </div>
                    </div>
                )}
                <motion.div
                    className="card-content p-4 md:p-1"
                    layoutId={`card-container-${id}`}
                >
                    <motion.div
                        className="title-container flex flex-col justify-between h-full"
                        layoutId={`title-container-${id}`}
                    >
                        <div className="flex w-full items-start justify-between">
                            <div>
                                <h2 className="text-2xl text-lightBlue font-semibold text-left">
                                    {name}
                                </h2>
                                <h3 className="text-md font-light w-fit px-2 rounded-lg mt-2 mb-4 bg-darkBlue text-white">
                                    {category}
                                </h3>
                                <p className="font-light">
                                    {department}: {jobTitle}
                                </p>
                            </div>

                            <button
                                onClick={() => {
                                    document.body.style.overflow = 'hidden';
                                    setShowModal(true);
                                }}
                                className="text-left text-lightBlue font-medium whitespace-nowrap flex items-start"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 mr-1"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                Read more
                            </button>
                        </div>

                        <button
                            onClick={(e) => {
                                if (votingEnabled) {
                                    castVote(id, category, name, department, jobTitle, user);
                                    testVotingEnabled();
                                    // setVotingEnabled(false);
                                }
                            }}
                            className={`${
                                votingEnabled && !hasVote
                                    ? 'bg-blue-500 cursor-pointer'
                                    : 'bg-gray-400 cursor-not-allowed'
                            }  text-white text-2xl px-8 py-3  rounded-md`}
                        >
                            {votingEnabled && !hasVote ? 'Vote Now' : 'Already Voted'}
                        </button>
                    </motion.div>
                </motion.div>
            </div>
            {showModal && (
                <div
                    className="fixed m-auto inset-0 bg-black bg-opacity-60 flex items-center justify-center z-10"
                    onClick={() => {
                        document.body.style.overflow = 'auto';
                        setShowModal(false);
                    }}
                >
                    <div className="bg-white p-8 rounded-2xl text-gray-600 w-11/12 md:w-1/2 motivation-modal">
                        <Motivation
                            data={motivation}
                        />
                        {/* <h2 className="text-2xl mb-4 text-lightBlue font-semibold">
              {name}
            </h2>
            <p>{motivation}</p> */}
                    </div>
                </div>
            )}
            {/* <Link to={`/nominations/${id}`} className={`card-open-link`}>
        Read more
      </Link> */}
        </li>
    );
}

export default Card;

// export function List({
//   selectedId,
//   nominationsData,
//   votingEnabled,
//   setVotingEnabled,
// }) {
//   return (
//     <>
//       {nominationsData.length > 0 ? (
//         <ul className="card-list px-4 md:px-0 grid md:grid-cols-2 gap-8">
//           {/* {items.map((card) => ( */}
//           {nominationsData &&
//             nominationsData.map((card) => (
//               <Card
//                 key={card.id}
//                 {...card}
//                 name={`${card.first_name_of_person_you_are_nominating} ${card.last_name_of_person_you_are_nominating}`}
//                 isSelected={card.id === selectedId}
//                 department={card.department}
//                 jobTitle={card.altron_fintech_job_title}
//                 id={card.id}
//                 category={card.award_category}
//                 votingEnabled={votingEnabled}
//                 setVotingEnabled={setVotingEnabled}
//                 // motivation={card.motivation_for_nomination}
//                 motivation={
//                   card.motivationData
//                     ? card.motivationData
//                     : card.motivation_for_nomination
//                 }
//               />
//             ))}
//         </ul>
//       ) : (
//         <p className="text-center font-bold text-gray-400 mx-14">
//           Sorry - there are no nominations for this award
//         </p>
//       )}
//     </>
//   );
// }
